<template>
    <!-- 科技进展 -->
    <div class="progress">
        <topHeader ind="4"></topHeader>
        <div class="pro_cont">
            <PageContent :title="'科技进展'" :total="total" :list="noticeList" @getArticleList="getArticleList"/>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import axios from '../http/axios'
    import PageContent from '../comm/pageContent.vue'
    export default {
        data(){
            return{
                noticeList:[],
                total:null
            }
        },
        components:{
            PageContent
        },
        mounted(){
            this.getArticleList()
        },
        methods:{
            getArticleList(page=1,page_number=5){
                axios.post('article/list',{page,page_number,category_name:'progress'}).then(res=>{
                    this.noticeList = res.data.data
                    this.total = res.data.total
                })
            }
        }
    }
</script>

<style scoped lang='less'>

</style>